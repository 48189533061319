import axios from 'axios'
import store from '@/store';
import router from '../router/index';
import { Message } from 'element-ui';

const service = axios.create({
    baseURL: 'https://workorder-api.hibuddy.com.cn',
    // baseURL: process.env.VUE_APP_API_ENDPOINT,
    // 手机端访问地址 candidate.hibuddy.com.cn ?拼接参数
    timeout: 50000
})
service.defaults.retry = 3; //断线重连 次数
service.defaults.retryDelay = 1000; //断线重连 间隔时间


service.interceptors.request.use(function (config) {
    store.state.api_loading = true;

    config.headers['language'] = "ch";

    if (sessionStorage.getItem('userid')) {
        config.headers['userid'] = crypto.get(sessionStorage.getItem('userid'));
    }
    if (sessionStorage.getItem('token')) {
        config.headers['token'] = crypto.get(sessionStorage.getItem('token'));
    }

    return config;
}, function (error) {
    if(error){
        store.state.api_loading = false;
        Message.error(error.response.status+" - "+error.response.statusText);
    }
    return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(function (response) {
    store.state.api_loading = false;

    if(router.currentRoute.path != "/login"){
        if(response.data.code==9400){
            Message.error(response.data.msg);
            sessionStorage.clear();
            // router.push('/login');
        }
    }

    return response;
}, function (error) {
    if(error){
        store.state.api_loading = false;
        Message.error(error.response.status+" - "+error.response.statusText);
    }
    return Promise.reject(error);
});

export default service