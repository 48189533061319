import request from '@/utils/request'

// 获取⾯试信息
export const getWorkOrder = data => request({
    url: '/api/v1/candidatesapi/getWorkOrder',
    method: 'post',
    data
})
// 修改⾯试时间
export const modifyInterviewTime = data => request({
    url: '/api/v1/candidatesapi/modifyInterviewTime',
    method: 'post',
    data
})
// 取消⾯试
export const cancel = data => request({
    url: '/api/v1/candidatesapi/cancel',
    method: 'post',
    data
})
// 是否参加⾯试⾯试
export const isAttend = data => request({
    url: '/api/v1/candidatesapi/isAttend',
    method: 'post',
    data
})
// 签到
export const signin = data => request({
    url: '/api/v1/candidatesapi/signin',
    method: 'post',
    data
})
// 线上面试链接
export const interviewUrl = data => request({
    url: '/api/v1/candidatesapi/interviewUrl',
    method: 'post',
    data
})
